// This file is responsible for setting up import shims on dev sites.
// It's a global script that is executed before the impport maps so no import() statements can be present here

type ImportMap = {
    imports: Record<string, string>,
    scopes?: Record<string, Record<string, string>>
};

function init() {
    const vConsole =  window['console'];
    // const baseCdnUrl = document.querySelector<HTMLMetaElement>('meta[name="o365-cdn-base-url"]')?.content;
    const staticScriptsBaseUrl = `${window.location.origin}/nt/scripts/`;
    let importMapCheckDone = false;

    (window as any).esmsInitOptions = {
        shimMode: true,
        resolve: async (pId: string, pParentUrl: string, pResolve: any) => {
            if (!importMapCheckDone) {
                validateInitialMaps();                
            }

            if (self?.__o365_shims__?.logResolve) {
                self.__o365_shims__.logResolve(pId, pParentUrl);
            }

            await populateCheckedOutLibraries();

            if (checkedOutLibraries.has(pId)) {
                const result = await customLibraryResolve(pId, pParentUrl);
                return result;
            } else {
                return pResolve(pId, pParentUrl);
            }
        },
        // fetch: (pUrl: string, pOptions: any) => {
        //     return fetch(pUrl, pOptions)
        // },
        mapOverrides: true,
    }
    

    const checkedOutLibraries = new Set<string>();

    // Temp enfoce o365-designer from appcache. Libs do not have support for css files on the cdn...
    checkedOutLibraries.add('o365-designer');
    if (window.location.host == 'localhost' || window.location.host == 'dev-test.omega365.com') {
        checkedOutLibraries.add('o365-login');
    }

    const importMapsCache = new Map<string, string>();

    function getImportMapsNode() {
        return document.querySelector('script[type="importmap-shim"]');
    }

    /**
     * Make sure that importShim maps are populated with entries from
     * script[type="importmap-shim"] 
     */
    function validateInitialMaps() {
        importMapCheckDone = true;
        const maps = (window as any).importShim.getImportMap();
        if (maps == null || maps.imports == null || !Object.keys(maps.imports).length) {
            const importMaps = JSON.parse(getImportMapsNode()!.innerHTML);
            (window as any).importShim.addImportMap(importMaps);
        }
    }

    async function customLibraryResolve(pId: string, _pParentUrl: string) {
        const indexUrl = await getAppCacheModuleIndexPromise(pId);
        return indexUrl;
    }

    const appCacheModuleIndexPromises = new Map<string, Promise<string | undefined>>();

    function getAppCacheModuleIndexPromise(pAppId: string) {
        if (appCacheModuleIndexPromises.has(pAppId)) {
            return appCacheModuleIndexPromises.get(pAppId)!;
        }
        const promise = getAppCacheModuleIndex(pAppId);
        appCacheModuleIndexPromises.set(pAppId, promise);
        return promise;
    }

    async function getAppCacheModuleIndex(pAppId: string) {
        if (!importMapsCache.has(pAppId)) {
            const result = await fetch(`/api/staticfiles/import-map/appfiles/${pAppId}.json?fingerprint=${getDependenciesFingerPrint(pAppId)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => res.json())
                .catch(() => ({ imports: {} }));
            transformMaps(result, pAppId);

            const loadedMaps = (window as any).importShim.getImportMap();
            const newMaps = {
                imports: {...loadedMaps.imports, ...result.imports},
                scopes: { ...loadedMaps.scopes, ...result.scopes}
            };

            (window as any).importShim.addImportMap(newMaps);

            importMapsCache.set(pAppId, result.imports[pAppId]);
        }

        return importMapsCache.get(pAppId);
    }

    function transformMaps(pMap: ImportMap, pModuleId: string) {
        if (pMap.scopes == null) {
            pMap.scopes = {};
        };

        const scopePrefix = `/nt/scripts/apps/${pModuleId}/`;
        let indexUrl = '';
        let removedEntries: [string, string][] = [];
        for (const key in pMap.imports) {
            // This part could cause issues for libs that dont use the '-index.ts' naming for their main entries. 
            // Look into if we can add more meta info to /api/staticfiles/import-map/appfiles/ route.
            if (key.endsWith('index.ts') || key.endsWith('index.js') ) {
                indexUrl = pMap.imports[key];
                delete pMap.imports[key];
            } else if (key.startsWith(scopePrefix)) {
                const url = pMap.imports[key];
                delete pMap.imports[key];
                removedEntries.push([key.replace(scopePrefix, ''), url]);
            }
        }

        for (const entry of removedEntries) {
            pMap.imports[entry[0]] = entry[1];
        }

        const copy = {...pMap.imports};
        const moduleScope = '/nt/scripts/apps/' + pModuleId + '/'
        pMap.scopes[moduleScope] = {};
        pMap.imports = {
            [pModuleId]: indexUrl,
        };
        for (const entry of Object.entries(copy)) {
            pMap.scopes[moduleScope][moduleScope + entry[0]] = entry[1];
        }
    }

    function getModuleScopeFromUrl(pUrl: string) {
        if (pUrl.startsWith(staticScriptsBaseUrl)) {
            if (pUrl.startsWith(staticScriptsBaseUrl + 'site')) {
                return 'site';
            } else {
                return pUrl.split('/').at(-2);
            }
        } else {
            return undefined;
        }
    }

    let checkedOutLibrariesPopulatedPromise: Promise<void> | null = null;
    async function populateCheckedOutLibraries() {
        if (checkedOutLibrariesPopulatedPromise) { return checkedOutLibrariesPopulatedPromise; }
        checkedOutLibrariesPopulatedPromise = new Promise<void>(async (res) => {
            try {

                if (window.self !== window.top) {
                    // This is an iframe, check if the top window has any overrides
                    const libs = window.top?.__o365_shims__?.checkedOutLibraries;
                    if (libs) {
                        for (const lib of libs) {
                            checkedOutLibraries.add(lib);
                        }
                        res();
                        return;
                    }
                }

                const params = new URLSearchParams(window.location.search);
                if (params.has('o_libs')) {
                    // Query parameters contain checkout overrides. Add only these ones.
                    const libs = params.get('o_libs')?.split(',') ?? [];
                    for (const lib of libs) {
                        checkedOutLibraries.add(lib);
                    }
                    if (window.__o365_shims__ == null) {
                        window.__o365_shims__ = {};    
                    }
                    window.__o365_shims__.checkedOutLibraries == Array.from(checkedOutLibraries);
                    
                } else {
                    // Load library checkouts from local storage 
                    const checkedOutLibs = localStorage.getItem('o365-dev-modules-shims_checked-out-app-libs');
                    if (checkedOutLibs) {
                        const libs = JSON.parse(checkedOutLibs);
                        for (const lib of libs) {
                            checkedOutLibraries.add(lib);
                        }
                    }
                }
            } catch (ex) {
                console.warn(ex);
            }

            // TODO: Change this to IndexDB so that it can be used as an in-browser file system
            // const libs = await db_retrieveCheckedOutLibs();
            res();
        });
        return checkedOutLibrariesPopulatedPromise;
    }

    /**
     * Helper function for getting the fingerprint of a library.
     */
    function getDependenciesFingerPrint(pId: string) {
        let dependenciesFingerPrint = document.querySelector<HTMLMetaElement>(`meta[name="o365-app-dependency-fingerprint-${pId}"]`)?.content;
        if (!dependenciesFingerPrint) {
            vConsole.warn(`No tag helper found for ${pId} dependencies fingerprints, will use random number instead`);
            dependenciesFingerPrint = `${Math.round((Math.random() * 10_000))}`;
        }
        return dependenciesFingerPrint;
    }

    // --- IndexDB ---

    // This part is not used for now. Will be utilized later on for loading local changes per user.

    let dbPromise: Promise<IDBDatabase | undefined> | undefined; 
    function getDb() {
        if (dbPromise) {
            return dbPromise;
        }
        const DBOpenRequest = indexedDB.open('o365-dev-modules-shims', 1);
        let db: IDBDatabase | undefined = undefined;

        let resovleDbOpen = (_pDb?: IDBDatabase) => {};
        dbPromise = new Promise<IDBDatabase | undefined>((res) => {
            resovleDbOpen = res;
        });

        DBOpenRequest.onerror = () => {
            vConsole.error('Failed to open database for library overrides on import maps')
            resovleDbOpen();
        };

        DBOpenRequest.onsuccess = () => {
            db = DBOpenRequest.result;
            resovleDbOpen(db);
        };

        DBOpenRequest.onupgradeneeded = () => {
            db = DBOpenRequest.result;

            // db.checked-out-app-libs
            let objectStore = db.createObjectStore('checked-out-app-libs', { keyPath: 'ID', autoIncrement: false },)
        };

        return dbPromise;
    }

    async function db_retrieveCheckedOutLibs() {
        const db = await getDb();
        if (db == null) { return; }

        const transaction = db.transaction(['checked-out-app-libs'], 'readonly');
        const objectStore = transaction.objectStore('checked-out-app-libs');

        const result = await wrapTransactionRequestToPromise(objectStore.getAll());

        return result;
    }

    function wrapTransactionRequestToPromise<T = any>(pRequest: IDBRequest<T>) {
        let resolve = (_r: T) => {};
        let reject = (_ex: DOMException | null) =>  {};
        const promise = new Promise<T>((res, rej) => {
            resolve = res;
            reject = rej;
        });

        pRequest.onsuccess = () => {
            resolve(pRequest.result);
        };

        pRequest.onerror = () => {
            reject(pRequest.error);
        };

        return promise;
    }

}

interface Window {
    __o365_shims__?: {
        checkedOutLibraries?: string[];
        logResolve?: (pId: string, pParentUrl?: string) => void;
    }
};


init();
